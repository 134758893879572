import React, { FC } from 'react';
import { graphql, Link } from 'gatsby';

import Layout from 'layout/Layout';
import Image from 'common/Image';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { convertDate, filterByTagId, stripHTML } from '../../utils/functions';
import { ArticleListProps } from './models';

import './ArticleList.scss';

const ArticleList: FC<{ data: ArticleListProps }> = ({
  data: {
    articleList: { urls, seo, title, image, tags },
    allArticle: { nodes: allItems },
  },
}) => {
  const { title: seoTitle, keywords: seoKeywords, description: seoDescription } = seo;

  const quantity = 6;
  const articles = filterByTagId(allItems, tags.id);
  const [items, setItemsState] = React.useState(articles.slice(0, quantity));
  const [selected, setSelectedState] = React.useState(0);

  const paginate = (page) => {
    setSelectedState(page);
    setItemsState(articles.slice(page * quantity, page * quantity + quantity));
  };

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={seoTitle}
        data={{
          metaTitle: seoTitle,
          metaDescription: seoDescription,
          metaKeywords: seoKeywords,
        }}
      />
      <div className="article-list">
        <div className="article-list__image">
          <Image imageData={image} alt={title} className="w-100" />
          <h2 className="ps-5 text-uppercase">{title}</h2>
        </div>
        <div className="container section-container pt-5">
          <div className="row">
            {items.map((article) => (
              <div className="col-12 col-md-4 mb-4" key={article.id}>
                <div className="article-list__item h-100 p-3">
                  <div>
                    <Link to={article.url}>
                      <Image imageData={article.image} alt={article.name} className="w-100" />
                    </Link>
                  </div>
                  <div className="px-3 pt-1 pb-4">
                    <h5 className="mt-3 mb-0">
                      <Link to={article.url}>{article.name}</Link>
                    </h5>
                    <div className="article-list__item-date">{convertDate(article.date)}</div>
                    <div className="article-list__item-description pt-2">
                      {`${stripHTML(article.body).substring(0, 150)}...`}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col">
              <nav>
                <ul className="pagination justify-content-end">
                  {Array(Math.ceil(articles.length / quantity))
                    .fill(1)
                    .map((value, index) => (
                      <li className={`page-item${index === selected ? ' active' : ''}`} key={value}>
                        <button type="button" className="page-link" onClick={() => paginate(index)}>
                          {index + 1}
                        </button>
                      </li>
                    ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($url: String) {
    articleList(url: { eq: $url }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
      title
      image {
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920, quality: 100)
          }
        }
      }
      tags {
        id
      }
    }
    allArticle {
      nodes {
        id
        url
        name
        date
        body
        image {
          desktop {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          mobile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
          tablet {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 800, quality: 100)
            }
          }
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export default ArticleList;
